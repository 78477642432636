.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gradient-text {
  background: linear-gradient(90deg, rgba(222,67,51,1) 20%, rgba(253,161,16,1) 35%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-div {
  background: linear-gradient(90deg, rgba(222,67,51,1) 5%, rgba(253,161,16,1) 10%);
  -webkit-text-fill-color: transparent;
}

textarea {
  resize: none !important;
}

.services-table {
  width: 100%;
  border-collapse: collapse;
}

.services-table th,
.services-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}

.services-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-table th,
.payment-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}

.payment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.service-table {
  width: 100%;
  border-collapse: collapse;
}

.service-table th,
.service-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
  font-size: 12px;
}
#table-scroll {
  overflow-x:auto;
  margin-top:20px;
}
.service-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 12px;
}

.burger-icon {
  transition: transform 0.3s ease-in-out;
}

.close-icon {
  transition: opacity 0.3s ease-in-out;
  transform: rotate(90deg);
}

.close-icon.open {
  opacity: 1;
}

.close-icon.closed {
  opacity: 0;

}

iframe {
  overflow: hidden !important;
  border: none !important;
}



.modalDiv {
  border-top-left-radius: 30% 3rem;
  border-top-right-radius: 30% 3rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.ddd {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-right-radius: 20px;
}


input {
  background-color: white !important;
}

input:focus,
input:not(:placeholder-shown) {
  background-color: white !important;
}

input:-webkit-autofill {
  background-color: white !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}